import { useEffect } from 'react';
import { useNavigate } from 'react-justanother-router';

import {
  CreateOrganizerMutation,
  CreateOrganizerMutationVariables,
  useCreateOrganizerMutation,
} from '@/apolloGenerated';
import { RouterName } from '@/app/router';
import { MutationResult } from '@apollo/client';
import { toaster } from '@letsdance/ui-kit';

import { getOrganizerInput } from './getOrganizerInput';

export const useCreateOrganizer = (): [
  (vars: CreateOrganizerMutationVariables) => Promise<void>,
  MutationResult<CreateOrganizerMutation>,
] => {
  const { navigate } = useNavigate();
  const [createOrganizer, result] = useCreateOrganizerMutation();

  const onCreateOrganizer = async ({
    input,
  }: CreateOrganizerMutationVariables) => {
    await createOrganizer({
      variables: {
        input: getOrganizerInput(input),
      },
    });
  };

  useEffect(() => {
    if (result.data) {
      const { client, data } = result;

      client.cache.evict({
        args: { id: result.data.createOrganizer?.id },
        fieldName: 'organizersList',
      });
      navigate(RouterName.AdminOrganizerEdit, {
        id: data?.createOrganizer.id,
      });
      toaster.success({ title: 'Организатор успешно создан' });
    }
  }, [result.data, result.error]);

  return [onCreateOrganizer, result];
};
