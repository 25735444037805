import { FC } from 'react';

import { useReferralFinanceStatsQuery } from '@/apolloGenerated';
import { StatItem, useOrganizerId } from '@/shared';
import { Divider } from '@letsdance/ui-kit';

import styles from './styles.module.scss';

export interface ReferralFinanceStatsProps {
  filterSlot?: JSX.Element;
  exportSlot?: JSX.Element;
}
export const ReferralFinanceStats: FC<ReferralFinanceStatsProps> = ({
  exportSlot,
  filterSlot,
}) => {
  const organizerId = useOrganizerId()!;
  const { data } = useReferralFinanceStatsQuery({
    skip: !organizerId,
    variables: { organizerId },
  });
  const stats = data?.referralFinanceStats;
  const balance = data?.organizer.referralBalance || 0;
  const orderCount = data?.ordersReferralOrganizerList.count || 0;
  const withdrawals = stats?.withdrawal || 0;
  const profit = stats?.profit || 0;

  return (
    <div className={styles.financeStats}>
      <div className={styles.financeStats__totalBlock}>
        <StatItem
          label="Реферальный баланс, ₽"
          value={balance}
          format
          fixed={false}
        />
        <Divider />
        <StatItem label="Реф. покупок" value={orderCount} />
      </div>
      <div className={styles.financeStats__statsBlock}>
        {filterSlot && (
          <div className={styles.financeStats__filterWrap}>
            {filterSlot}
            {exportSlot}
          </div>
        )}

        <div className={styles.financeStats__statsWrap}>
          <StatItem label="Получено, ₽" value={profit} format fixed={false} />
          <Divider vertical />
          <StatItem
            label="Выведено, ₽"
            value={withdrawals}
            format
            fixed={false}
          />
        </div>
      </div>
    </div>
  );
};
