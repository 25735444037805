import { gql } from '@apollo/client';

export const financeStats = gql`
  query financeStats($organizerId: Int!) {
    paymentsList(
      filters: { pagination: { page: 1, pageSize: 1 } }
      args: { organizerId: $organizerId }
    ) {
      count
    }
    salesFinanceStats(organizerId: $organizerId) {
      profit
      commission
      turnover
      withdrawal
      referralDeduction
    }
    organizer(id: $organizerId) {
      salesBalance
    }
  }
`;

export const organizerBalances = gql`
  query organizerBalances($organizerId: Int!) {
    organizer(id: $organizerId) {
      salesBalance
      referralBalance
    }
  }
`;

export const purchaseStats = gql`
  query purchaseStats($organizerId: Float!, $uuidProduct: UUID) {
    purchasesStats(organizerId: $organizerId, uuidProduct: $uuidProduct) {
      usersCounts {
        all
        female
        male
      }
      profit
    }
  }
`;

export const paymentsList = gql`
  query paymentsList(
    $filter: PaymentsListFilterInput
    $organizerId: Int!
    $uuidUser: UUID
    $status: [PaymentStatusEnum!] = [Success]
  ) {
    paymentsList(
      filters: $filter
      args: { organizerId: $organizerId, uuidUser: $uuidUser }
      status: $status
    ) {
      count
      rows {
        uuid
        price
        createdAt
        payment_uuid
        status
        user {
          first_name
          last_name
          email
          username
          uuid
        }
        order {
          id
          status
          fixedHoldPercentage
          referralOrganizer {
            key
            percentage
          }
          product {
            name
            startedDate
            endDate
          }
          userBalanceWithdrawal {
            amount
          }
          tariff {
            name
          }
          pairOrder {
            user {
              first_name
              last_name
              email
              username
              uuid
              phone
            }
          }
        }
      }
    }
  }
`;

export const financeListsCount = gql`
  query financeListsCount($idOrganizer: Int!) {
    paymentsList(
      args: { organizerId: $idOrganizer }
      filters: { pagination: { pageSize: 1, page: 1 } }
    ) {
      count
    }
    withdrawalsByOrganizer(
      idOrganizer: $idOrganizer
      filters: { pagination: { pageSize: 1, page: 1 } }
      from: SalesBalance
    ) {
      count
    }
  }
`;
