declare global {
  interface Window {
    settings: {
      VITE_ENDPOINT: string;
      VITE_SENTRY_DSN: string;
      VITE_ENVIROPMENT: string;
      VITE_BOT_NAME: string;
      VITE_CREATE_REFERRALS_IDS: string;
      VITE_REFERRALS_IDS: string;
    };
  }
}

const app_config = {
  API_URL: window?.settings?.VITE_ENDPOINT || import.meta.env?.VITE_ENDPOINT,
  BOT_NAME: window?.settings?.VITE_BOT_NAME || import.meta.env?.VITE_BOT_NAME,
  ENVIROPMENT:
    window?.settings?.VITE_ENVIROPMENT || import.meta.env?.VITE_ENVIROPMENT,
  SENTRY_DSN:
    window?.settings?.VITE_SENTRY_DSN || import.meta.env?.VITE_SENTRY_DSN,
  featureFlags: {
    createReferralIds: (
      window?.settings?.VITE_CREATE_REFERRALS_IDS ||
      import.meta.env?.VITE_CREATE_REFERRALS_IDS ||
      ''
    )
      .split(',')
      .map((el: string) => +el),
    referralIds: (
      window?.settings?.VITE_REFERRALS_IDS ||
      import.meta.env?.VITE_REFERRALS_IDS ||
      ''
    )
      .split(',')
      .map((el: string) => +el),
  },
};

export default app_config;
