import { FC, PropsWithChildren, useState } from 'react';
import cx from 'classnames';

import { MeQuery } from '@/apolloGenerated';
import { Footer } from '@shared/ui/Footer/Footer';

import { Header } from '../Header/Header';
import { MobileHeader } from '../MobileHeader/MobileHeader';

import styles from './styles.module.scss';

export interface BaseProps {
  user?: MeQuery['me'];
}

export const Base: FC<PropsWithChildren<BaseProps>> = ({
  children,
  user,
}): JSX.Element => {
  const [isOpened, setIsOpened] = useState(false);

  return (
    <div className={styles.base}>
      <MobileHeader
        className={cx(
          styles.base__mobileHeader,
          !isOpened && styles.base__mobileHeader_default,
        )}
        onClick={() => setIsOpened(!isOpened)}
      />
      <div
        className={cx(
          styles.base__header,
          isOpened && styles.base__header_open,
        )}>
        <Header user={user} onNavClick={() => setIsOpened(false)} />
      </div>
      <div className={styles.content} onClick={() => setIsOpened(false)}>
        <main className={styles.base__main}>
          <div className={styles.base__children}>{children}</div>
          <Footer />
        </main>
      </div>
    </div>
  );
};
