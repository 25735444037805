import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-justanother-router';
import moment from 'moment-timezone';

import {
  Maybe,
  OrdersListQuery,
  OrderSortKeys,
  OrderStatusEnum,
  OrderType,
  useOrdersListQuery,
} from '@/apolloGenerated';
import {
  ActiveIcon,
  calculateAmounts,
  formatAmount,
  formatEmpty,
  formatSort,
  renderCellItemValue,
  ScrollXWrapper,
  timezone,
  useOrganizerId,
} from '@/shared';
import { RouterName } from '@app/router';
import { OrderEventInfoCell } from '@entities/Purchases';
import { isSuccessPayment } from '@entities/Purchases/libs/utils';
import { OrderControl } from '@features/Orders';
import { LoaderOverlay, SortType, TableController } from '@letsdance/ui-kit';

const pageSize = 10;
const defaultPage = 1;

export interface PurchasesOrdersTableProps {
  uuidProduct: Maybe<string>;
  status: OrderStatusEnum[];
}
export const PurchasesOrdersTable: FC<PurchasesOrdersTableProps> = ({
  status,
  uuidProduct,
}) => {
  const { urlFor } = useNavigate();
  const organizerId = useOrganizerId()!;
  const [page, setPage] = useState(defaultPage);
  const [sort, setSort] = useState<Maybe<SortType>>('desc');
  const [sortBy, setSortBy] = useState<OrderSortKeys>(OrderSortKeys.CreatedAt);
  const hasActions = status.includes(OrderStatusEnum.Paid);

  const headers = [
    { hide: true, key: true, value: 'id' },
    { sortable: true, title: 'Покупка', value: 'createdAt' },
    { title: 'Событие', value: 'event' },
    { title: 'Пользователь', value: 'user' },
    { title: 'Парный билет', value: 'pairUser' },
    { title: 'Потрачено, ₽ ', value: 'price' },
    // { title: 'Комиссия, ₽', value: 'commission' },
    // ...(refFlag ? [{ title: 'Реф. отч., ₽', value: 'referral' }] : []),
    // { title: 'Прибыль, ₽ ', value: 'profit' },
    { title: 'Потрачено, Баллы ', value: 'bonus' },
    { sortable: true, title: 'Активация', value: 'visitedAt' },
    ...(hasActions ? [{ title: '', value: 'settings', width: 64 }] : []),
  ];

  useEffect(() => {
    setPage(defaultPage);
  }, [status, uuidProduct]);

  const {
    data: curData,
    error,
    loading,
    previousData,
    refetch,
  } = useOrdersListQuery({
    skip: !organizerId,
    variables: {
      filter: {
        order: { sort: formatSort(sort), sortBy },
        pagination: { page, pageSize },
      },
      organizerId,
      status,
      uuidProduct,
    },
  });
  const data = curData || previousData;

  const rowTemplate = (order: OrdersListQuery['ordersList']['rows'][0]) => {
    const paymentAmounts = isSuccessPayment(order.payment?.status)
      ? calculateAmounts({
          holdPercentage: order.fixedHoldPercentage,
          price: order.payment?.price,
          referralPercentage: order.referralOrganizer?.percentage,
        })
      : null;
    const pairUser = order.pairOrder?.user;

    return {
      bonus: order.userBalanceWithdrawal?.amount
        ? formatAmount(order.userBalanceWithdrawal?.amount)
        : '-',
      commission: formatEmpty(
        paymentAmounts?.commission,
        `- ${formatAmount(paymentAmounts?.commission || 0)} (${order.fixedHoldPercentage}%)`,
      ),
      createdAt: renderCellItemValue({
        label: `ID: ${order.id}`,
        value: moment(order.createdAt).tz(timezone).format('DD MMM YYYY HH:mm'),
      }),
      event: <OrderEventInfoCell order={order as OrderType} />,
      id: String(order.id),
      pairUser: pairUser
        ? renderCellItemValue({
            label: `${pairUser.last_name || ''} ${pairUser.first_name}`,
            onClick: () =>
              window.open(
                urlFor(RouterName.AdminContactEdit, { uuid: pairUser.uuid }),
                '_blank',
              ),
            value: pairUser.phone || pairUser.email,
          })
        : '-',
      price: paymentAmounts?.turnover
        ? formatAmount(paymentAmounts.turnover)
        : '-',
      profit: formatEmpty(
        paymentAmounts?.profit,
        formatAmount(paymentAmounts?.profit || 0),
      ),
      referral: formatEmpty(
        paymentAmounts?.referral,
        `- ${formatAmount(paymentAmounts?.referral || 0)}`,
      ),
      settings:
        order.status === OrderStatusEnum.Paid ? (
          <OrderControl id={order.id} />
        ) : (
          ' '
        ),
      user: renderCellItemValue({
        label: `${order.user.last_name || ''} ${order.user.first_name}`,
        onClick: () =>
          window.open(
            urlFor(RouterName.AdminContactEdit, { uuid: order.user.uuid }),
            '_blank',
          ),
        value: order.user.phone || order.user.email,
      }),
      visitedAt: (
        <ActiveIcon
          style={{
            opacity:
              order.status === OrderStatusEnum.CompletedAndVisited ? 1 : 0,
          }}
        />
      ),
    };
  };
  const items = useMemo(
    () => (data?.ordersList.rows || []).map(rowTemplate),
    [data],
  );

  return (
    <div className="relative">
      <LoaderOverlay show={loading} />
      <ScrollXWrapper>
        <TableController
          hasError={!!error}
          data={items}
          headers={headers}
          total={data?.ordersList.count}
          onChangePage={setPage}
          pageSize={pageSize}
          initSort={sort}
          initSortBy={sortBy!}
          onSort={(sort, sortBy) => {
            setSort(sort);
            setSortBy(sortBy as OrderSortKeys);
          }}
          onRefetch={error && refetch}
        />
      </ScrollXWrapper>
    </div>
  );
};
