import React, { FC, useMemo, useState } from 'react';

import { UserType, useSubTariffQuery } from '@/apolloGenerated';
import { ScrollXWrapper } from '@/shared';
import {
  ContactListTabs,
  ContactTabs,
  ContactTabsEnum,
} from '@entities/Contact';
import { validateTariff } from '@entities/Tariff';
import { ContactTable, ContactTableHeader } from '@features/Contact';
import { useUsersList } from '@features/Contact/libs/hooks/useUsersList';
import { Maybe, Spacer } from '@letsdance/ui-kit';

export interface ContactOrderInviteSelectProps {
  tariffUuid?: Maybe<string>;
  productUuid?: Maybe<string>;

  onChange(uuids: string[]): void;

  requiredRegistration?: boolean;
  checkInvite?: boolean;
}

export const ContactOrderInviteSelect: FC<ContactOrderInviteSelectProps> = ({
  checkInvite,
  onChange,
  productUuid,
  requiredRegistration,
  tariffUuid,
}) => {
  const [tab, setTab] = useState<ContactTabs>(ContactTabsEnum.Special);
  const { data, error, fetchAll, loading, onUpdatePayload, payload, refetch } =
    useUsersList({
      productUuid,
      tab,
    });
  const { data: tariffData } = useSubTariffQuery({
    skip: !tariffUuid,
    variables: { uuid: tariffUuid! },
  });
  const validationData = useMemo(
    () => ({ type: tariffData?.subTariff?.baseTariff?.type }),
    [tariffData],
  );
  const [value, setValue] = useState<UserType[]>([]);

  const handleChange = (val: UserType[]) => {
    setValue(val);
    onChange(val.map((el) => el.uuid));
  };

  return (
    <div>
      <ScrollXWrapper>
        <ContactListTabs
          initTab={tab}
          onChange={setTab}
          hideAll
          hideActive
          hideBlocked
        />
      </ScrollXWrapper>

      <Spacer size={8} />
      <ScrollXWrapper>
        <ContactTable
          hasError={!!error}
          data={(data?.rows as UserType[]) || []}
          total={data?.count || 0}
          payload={payload}
          tab={tab}
          headers={[
            ContactTableHeader.Check,
            ContactTableHeader.Icon,
            ContactTableHeader.Name,
            ContactTableHeader.Data,
          ]}
          loading={loading}
          onChangePayload={onUpdatePayload}
          selected={value}
          fetchAllUsers={() => fetchAll()}
          onChangeSelect={handleChange}
          validator={({ hasInviteByProduct, hasOrderByProduct, ...user }) =>
            validateTariff(
              {
                hasInviteByProduct,
                hasOrderByProduct,
                tariffType: tariffData?.subTariff?.baseTariff?.type,
                userInfo: user,
              },
              {
                skipCheckInvite: !checkInvite,
                skipRegistered: !requiredRegistration,
              },
            )
          }
          validationData={validationData}
          resetSelectedOnChangeTab
          onRefetch={error && refetch}
        />
      </ScrollXWrapper>
    </div>
  );
};
