import { FC } from 'react';

import { createTgLink } from '@/shared';
import app_config from '@app/constants';
import {
  Button,
  Maybe,
  toaster,
  Typography,
  useCopyToClipboard,
} from '@letsdance/ui-kit';

import styles from './styles.module.scss';

export interface DeeplinkCopyProps {
  to: string;
  value?: Maybe<string>;
  field?: string;
  label?: string;
}
export const DeeplinkCopy: FC<DeeplinkCopyProps> = ({
  field = 'query',
  label,
  to,
  value,
}) => {
  const [, copyToClipboard] = useCopyToClipboard();

  if (!value) {
    return (
      <Typography variant="body-14" color="on-surface-primary-2">
        Для получения диплинка заполните поле {field}
      </Typography>
    );
  }
  const link = `${createTgLink(app_config.BOT_NAME)}?start=${to}-${value}`;

  return (
    <div className={styles.deepLink}>
      <div>
        <Typography variant="body-14" color="on-surface-primary-2">
          {label || 'Ссылка для автоматического перехода'}
        </Typography>
        <Typography
          tag="a"
          // @ts-ignore
          href={link}
          target="_blank"
          variant="body-14"
          color="primary-1">
          {link}
        </Typography>
      </div>
      <Button
        variant="outlined"
        size="small"
        onClick={() => {
          copyToClipboard(link);
          toaster.success({ title: 'Диплинк скопирован в буфер обмена' });
        }}>
        Скопировать
      </Button>
    </div>
  );
};
