import { useCheckOrganizerTerminalLazyQuery } from '@/apolloGenerated';
import { useOrganizerId } from '@/shared';
import { toaster } from '@letsdance/ui-kit';

export const useCheckOrganizerTerminal = () => {
  const organizerId = useOrganizerId()!;
  const [checkTerminalKey] = useCheckOrganizerTerminalLazyQuery({
    variables: {
      id: organizerId,
    },
  });

  const checkOrganizerTerminal = async (): Promise<void> => {
    const { data } = await checkTerminalKey();

    if (!data?.organizer?.hasTerminalKey) {
      toaster.warning({
        title:
          'Для работы онлайн-оплаты необходимо заполнить Платёжную информацию',
      });
    }
  };

  return { checkOrganizerTerminal };
};
