import { FC, useMemo, useState } from 'react';

import {
  Maybe,
  ReferralOrganizerSortKeys,
  ReferralsOrganizerListQuery,
  useReferralsOrganizerListQuery,
} from '@/apolloGenerated';
import {
  Container,
  formatEmpty,
  formatSort,
  renderCellDate,
  useOrganizerId,
} from '@/shared';
import { renderCellEventName } from '@entities/Event';
import { createTgReferralLink } from '@features/Referral/libs/utils';
import {
  LoaderOverlay,
  SortType,
  TableController,
  Typography,
} from '@letsdance/ui-kit';
import { readableStatus } from '@shared/libs/utils/format';

const headers = [
  {
    hide: true,
    key: true,
    value: 'key',
  },
  { sortable: true, title: 'Дата создания', value: 'createdAt', width: 160 },
  {
    sortable: true,
    title: 'Статус',
    value: 'status',
  },
  {
    title: 'Организатор',
    value: 'organizer',
  },
  {
    title: 'Мероприятие',
    value: 'event',
  },
  {
    sortable: true,
    title: 'Реф. ссылка',
    value: 'link',
  },
  {
    sortable: true,
    title: 'Процент удержания',
    value: 'percentage',
  },
  {
    title: 'Кол-во покупок',
    value: 'ordersCount',
  },
];
const pageSize = 15;

export const ReferralTable: FC = () => {
  const idUsedOrganizer = useOrganizerId()!;
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState<Maybe<SortType>>('desc');
  const [sortBy, setSortBy] = useState<ReferralOrganizerSortKeys>(
    ReferralOrganizerSortKeys.CreatedAt,
  );

  const {
    data: curData,
    error,
    loading,
    previousData,
    refetch,
  } = useReferralsOrganizerListQuery({
    variables: {
      filters: {
        order: { sort: formatSort(sort), sortBy },
        pagination: { page, pageSize },
      },
      params: {
        idUsedOrganizer,
      },
    },
  });

  const data = curData || previousData;
  const rowTemplate = ({
    createdAt,
    key,
    ordersCount,
    percentage,
    product,
    status,
  }: ReferralsOrganizerListQuery['referralsOrganizerList']['rows'][0]) => {
    const link = createTgReferralLink(product.query || '', key);

    return {
      createdAt: renderCellDate({ time: true, timestamp: createdAt }),
      event: renderCellEventName({
        name: product.name,
        query: product.query,
        startedDate: product.startedDate,
      }),
      key,
      link: (
        <Typography
          tag="a"
          color="primary-1"
          // @ts-ignore
          href={link}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: 'underline' }}>
          {key}
        </Typography>
      ),
      ordersCount: formatEmpty(ordersCount, ordersCount),
      organizer: product.organizer.name,
      percentage: `${percentage}%`,
      status: readableStatus(status),
    };
  };
  const items = useMemo(
    () => (data?.referralsOrganizerList.rows || []).map(rowTemplate),
    [data],
  );

  return (
    <Container className="relative">
      <LoaderOverlay show={loading} />
      <TableController
        hasError={!!error}
        data={items}
        headers={headers}
        total={data?.referralsOrganizerList.count}
        onChangePage={setPage}
        pageSize={pageSize}
        initSort={sort}
        initSortBy={sortBy!}
        onSort={(sort, sortBy) => {
          setSort(sort);
          setSortBy(sortBy as ReferralOrganizerSortKeys);
        }}
        notResetPage
        onRefetch={error && refetch}
      />
    </Container>
  );
};
