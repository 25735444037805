import React, { FC, useState } from 'react';

import { UserType } from '@/apolloGenerated';
import { FormSection, ScrollXWrapper } from '@/shared';
import {
  ContactListTabs,
  ContactTabs,
  ContactTabsEnum,
} from '@entities/Contact';
import { ContactTable, ContactTableHeader } from '@features/Contact';
import { useUsersList } from '@features/Contact/libs/hooks/useUsersList';
import { Spacer } from '@letsdance/ui-kit';

export interface ContactTariffAccessSelectProps {
  initValue?: UserType[];
  onChange(uuids: string[]): void;
}
export const ContactTariffAccessSelect: FC<ContactTariffAccessSelectProps> = ({
  initValue,
  onChange,
}) => {
  const [tab, setTab] = useState<ContactTabs>(ContactTabsEnum.All);
  const { data, error, fetchAll, loading, onUpdatePayload, payload, refetch } =
    useUsersList({
      tab,
    });
  const [value, setValue] = useState<UserType[]>(initValue || []);

  const handleChange = (val: UserType[]) => {
    setValue(val);
    onChange(val.map((el) => el.uuid));
  };

  return (
    <FormSection
      title={`Пользователи (${value.length > 0 ? value.length : 'доступно всем'})`}>
      <ScrollXWrapper>
        <ContactListTabs
          initTab={tab}
          onChange={setTab}
          hideActive
          hideRegistered
          hideBlocked
        />
      </ScrollXWrapper>

      <Spacer size={8} />
      <ScrollXWrapper>
        <ContactTable
          hasError={!!error}
          data={(data?.rows as UserType[]) || []}
          total={data?.count || 0}
          payload={payload}
          tab={tab}
          headers={[
            ContactTableHeader.Check,
            ContactTableHeader.Icon,
            ContactTableHeader.Name,
            ContactTableHeader.Data,
          ]}
          fetchAllUsers={() => fetchAll()}
          loading={loading}
          onChangePayload={onUpdatePayload}
          selected={value}
          onChangeSelect={handleChange}
          onRefetch={error && refetch}
        />
      </ScrollXWrapper>
    </FormSection>
  );
};
