import { FC } from 'react';

import { Container, ScrollXWrapper } from '@/shared';
import { Spacer } from '@letsdance/ui-kit';
import { ContactLists, ContactStats } from '@widgets/Contact';
import { Head } from '@widgets/Layout';

export const ContactView: FC = () => (
  <div>
    <Container>
      <Head title="Контакты" />
    </Container>
    <ScrollXWrapper>
      <ContactStats />
    </ScrollXWrapper>

    <Spacer size={12} />
    <ContactLists />
  </div>
);
