import { useOrganizerCommissionQuery } from '@/apolloGenerated';
import { useOrganizerId } from '@/shared';
import app_config from '@app/constants';

export const useFeatureFlags = () => {
  const organizerId = useOrganizerId()!;
  const { data } = useOrganizerCommissionQuery({
    skip: !organizerId,
    variables: {
      id: organizerId,
    },
  });

  return {
    commission: !!data?.organizer.holdPercentage,
    referral: app_config.featureFlags.referralIds.includes(organizerId),
    referralCreate:
      app_config.featureFlags.createReferralIds.includes(organizerId),
  };
};
