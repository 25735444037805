import { gql } from '@apollo/client';

export const checkOrganizerTerminal = gql`
  query checkOrganizerTerminal($id: Int!) {
    organizer(id: $id) {
      hasTerminalKey
    }
  }
`;

export const organizerFragment = gql`
  fragment OrganizerFields on OrganizerType {
    id
    name
    createdAt
    balance
    productsCount
    telegram
    ordersCount
    receipts
  }
`;

export const organizersListCount = gql`
  query organizersListCount($verified: Boolean) {
    organizersList(verified: $verified) {
      count
    }
  }
`;

export const organizerCommission = gql`
  query organizerCommission($id: Int!) {
    organizer(id: $id) {
      holdPercentage
    }
  }
`;

export const organizersList = gql`
  ${organizerFragment}
  query organizersList(
    $verified: Boolean
    $status: [StatusEnum!]
    $filters: OrganizerListFilterInput
  ) {
    organizersList(verified: $verified, status: $status, filters: $filters) {
      rows {
        ...OrganizerFields
      }
      count
    }
  }
`;

export const organizer = gql`
  query organizer($id: Int!) {
    organizer(id: $id) {
      createdAt
      updatedAt
      deletedAt
      id
      image
      name
      query
      description
      telegram
      socials(disableShortcode: true) {
        image
        text
        vk
        telegram
        facebook
        youtube
        instagram
      }
      supplierInfo {
        name
        inn
        phone
      }
      orderInstructions {
        transferToProduct
        transferToUser
        refund
      }
    }
  }
`;

export const organizerFiles = gql`
  query organizerFiles($id: Int!) {
    organizer(id: $id) {
      files {
        oferta
        agent
        policy
      }
    }
  }
`;

export const updateOrganizer = gql`
  mutation updateOrganizer($id: Float!, $input: OrganizerInput!) {
    updateOrganizer(id: $id, input: $input) {
      id
    }
  }
`;

export const createOrganizer = gql`
  mutation createOrganizer($input: OrganizerInput!) {
    createOrganizer(input: $input) {
      id
    }
  }
`;

export const organizerShortcodes = gql`
  query organizerShortcodes {
    organizerShortcodes
  }
`;
