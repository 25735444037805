import { FC, useEffect, useMemo, useState } from 'react';

import {
  Maybe,
  useOrganizerBalancesQuery,
  usePaymentAccountsListQuery,
  useWithdrawalRequestMutation,
  WithdrawalFromEnum,
} from '@/apolloGenerated';
import {
  ActionButtons,
  Container,
  formatAmount,
  useOrganizerId,
} from '@/shared';
import { MINIMAL_WITHDRAW_AMOUNT } from '@entities/Withdraw';
import {
  AmountField,
  Button,
  DropdownItem,
  Select,
  Spacer,
  toaster,
  Typography,
} from '@letsdance/ui-kit';
import { ActionButton } from '@shared/ui/ActionButtons/ActionButtons';
import { Head } from '@widgets/Layout';

import styles from './styles.module.scss';

export interface CreateWithdrawProps {
  type: WithdrawalFromEnum;
  onBack(): void;
}
export const CreateWithdraw: FC<CreateWithdrawProps> = ({ onBack, type }) => {
  const organizerId = useOrganizerId()!;

  const [withdrawalRequest, { client, data, error }] =
    useWithdrawalRequestMutation();
  const { data: balancesData } = useOrganizerBalancesQuery({
    skip: !organizerId,
    variables: { organizerId },
  });
  const balance =
    (type === WithdrawalFromEnum.SalesBalance
      ? balancesData?.organizer.salesBalance
      : balancesData?.organizer.referralBalance) || 0;
  const [amount, setAmount] = useState<Maybe<number>>(null);

  const { data: paymentsData } = usePaymentAccountsListQuery({
    skip: !organizerId,
    variables: { idOrganizer: organizerId },
  });
  const paymentItems = paymentsData?.paymentsByOrganizer || [];
  const [paymentUuid, setPaymentUuid] = useState<string>();

  const amountError = useMemo(() => {
    if (amount && amount > balance) {
      return 'Не хватает средств для вывода';
    }
    if (amount && amount < MINIMAL_WITHDRAW_AMOUNT) {
      return `Минимальная сумма для вывода ${formatAmount(MINIMAL_WITHDRAW_AMOUNT)}`;
    }
  }, [amount, balance]);

  const handleSubmit = () => {
    if (!amount) {
      return;
    }
    withdrawalRequest({
      variables: {
        input: {
          amount: amount,
          from: type,
          organizerId,
          uuidPaymentAccount: paymentUuid!,
        },
      },
    });
  };

  useEffect(() => {
    if (data) {
      client.cache.evict({ fieldName: 'withdrawalsByOrganizer' });
      client.cache.evict({ fieldName: 'organizer' });
      toaster.success({ title: 'Заявка на вывод средств успешно создана' });
      onBack();
    }
    if (error) {
      toaster.error({
        title:
          'Ошибка создания запроса на оплату. Повторите попытку позже или обратитесь в техподдержку',
      });
    }
  }, [data, error]);

  const actions: ActionButton[] = [
    { handler: onBack, label: 'Отменить' },
    {
      disabled: !paymentUuid || !amount || !!amountError,
      handler: handleSubmit,
      label: 'Подтвердить вывод',
    },
  ];

  return (
    <>
      <Container slim>
        <Head title="Вывод средств" onBack={onBack} />
      </Container>
      <ActionButtons actions={actions} />
      <Spacer size={20} />
      <Container slim>
        <Select
          size="large"
          placeholder="Счёт для вывода"
          initValue={paymentUuid}
          renderValue={(val) =>
            paymentItems.find((el) => el.uuid === val)?.name || ''
          }
          onChange={setPaymentUuid}
          hideOnSelect
          fullWidth>
          {paymentItems.map((el) => (
            <DropdownItem key={el.uuid} label={el.name} value={el.uuid} />
          ))}
        </Select>
        <Spacer size={12} />
        <div className={styles.withdraw__labelWrap}>
          <Typography variant="body-16" color="on-surface-primary-1">
            Доступно для вывода
          </Typography>
          <Button
            size="small"
            color="secondary"
            onClick={() => setAmount(balance)}>
            {formatAmount(balance, true)} ₽
          </Button>
        </div>
        <Spacer size={6} />
        <AmountField
          size="large"
          label="Сумма вывода"
          postfix="₽"
          value={amount}
          onChange={(e) => setAmount(+e.target.value)}
          error={amountError}
          fullWidth
          filterNumberParams={{ allowDecimal: true, decimalPlaces: 2 }}
        />
      </Container>
    </>
  );
};
