import { FC } from 'react';
import cx from 'classnames';

import { LogoIcon } from '@/shared';
import { SidebarIcon } from '@letsdance/ui-kit';
import { ProfileControl } from '@widgets/Layout/ui/ProfileControl/ProfileControl';

import styles from './styles.module.scss';

export interface MobileHeaderProps {
  className?: string;
  onClick?(): void;
}

export const MobileHeader: FC<MobileHeaderProps> = ({ className, onClick }) => (
  <div className={cx(styles.mobileHeader, className)}>
    <LogoIcon color="var(--neutrals-1)" className={styles.mobileHeader__logo} />
    <ProfileControl />
    <SidebarIcon onClick={onClick} color={'white'} />
  </div>
);
