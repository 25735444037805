import { createRoot } from 'react-dom/client';

import { Root } from './app';

import 'ckeditor5/ckeditor5.css';
import 'react-datepicker/dist/react-datepicker.css';

const rootNode = document.querySelector('#root')!;
const root = createRoot(rootNode);

root.render(<Root />);
