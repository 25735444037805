import { FC } from 'react';

import { useFinanceStatsQuery } from '@/apolloGenerated';
import { ScrollXWrapper, StatItem, useOrganizerId } from '@/shared';
import { Divider } from '@letsdance/ui-kit';
import { useFeatureFlags } from '@shared/libs/hooks/useFeatureFlags';

import styles from './styles.module.scss';

export interface FinanceStatsProps {
  filterSlot?: JSX.Element;
  exportSlot?: JSX.Element;
}
export const FinanceStats: FC<FinanceStatsProps> = ({
  exportSlot,
  filterSlot,
}) => {
  const { referral: refFlag } = useFeatureFlags();
  const organizerId = useOrganizerId()!;
  const { data } = useFinanceStatsQuery({
    skip: !organizerId,
    variables: { organizerId },
  });
  const stats = data?.salesFinanceStats;
  const balance = data?.organizer.salesBalance || 0;
  const orderCount = data?.paymentsList.count || 0;
  const commission = stats?.commission || 0;
  const withdrawals = stats?.withdrawal || 0;
  const turnover = stats?.turnover || 0;
  const referral = stats?.referralDeduction || 0;
  const profit = stats?.profit || 0;

  return (
    <div className={styles.financeStats}>
      <div className={styles.financeStats__totalBlock}>
        <StatItem
          label="Текущий баланс, ₽"
          value={balance}
          format
          fixed={false}
        />
        <Divider />
        <StatItem label="Покупок" value={orderCount} />
      </div>
      <div className={styles.financeStats__statsBlock}>
        {filterSlot && (
          <div className={styles.financeStats__filterWrap}>
            {filterSlot}
            {exportSlot}
          </div>
        )}
        <ScrollXWrapper>
          <div className={styles.financeStats__statsWrap}>
            <StatItem
              label="Поступило, ₽"
              value={turnover}
              format
              fixed={false}
            />
            <Divider vertical />
            <StatItem
              label="Комиссия, ₽"
              value={commission}
              format
              fixed={false}
            />
            <Divider vertical />
            {refFlag && (
              <>
                <StatItem
                  label="Реф. отч., ₽"
                  value={referral}
                  format
                  fixed={false}
                />
                <Divider vertical />
              </>
            )}
            <StatItem label="Прибыль, ₽" value={profit} format fixed={false} />
            <Divider vertical />
            <StatItem
              label="Выведено, ₽"
              value={withdrawals}
              format
              fixed={false}
            />
          </div>
        </ScrollXWrapper>
      </div>
    </div>
  );
};
