export enum RouterName {
  Home = 'home',
  Wiki = 'wiki',
  NotFound = '404',
  Authorization = 'auth',
  AdminEvents = 'admin-events',
  AdminBaseTariffs = 'admin--base-tariffs',
  AdminProfile = 'admin-profile',
  AdminContact = 'contact',
  AdminPurchases = 'admin-purchases',
  AdminFinance = 'admin-finances',
  AdminReferral = 'admin-referral',
  AdminReferralWithdrawCreate = 'admin-referral-withdraw-create',
  AdminWithdrawCreate = 'admin-withdraw-create',
  AdminContactEdit = 'admin-contact-edit',
  AdminEventEdit = 'admin-event-edit',
  AdminEventCreate = 'admin-event-create',
  AdminBaseTariffEdit = 'admin-base-tariff-edit',
  AdminBaseTariffCreate = 'admin-base-tariff-create',
  AdminOrganizers = 'admin-organizers',
  AdminOrganizerCreate = 'admin-organizer-create',
  AdminOrganizerEdit = 'admin-organizer-edit',
}
