import { FC } from 'react';
import { useNavigate } from 'react-justanother-router';

import { WithdrawalFromEnum } from '@/apolloGenerated';
import { RouterName } from '@app/router';
import { CreateWithdraw } from '@features/Withdraw';

export const CreateWithdrawView: FC = () => {
  const { navigate } = useNavigate();

  return (
    <CreateWithdraw
      type={WithdrawalFromEnum.SalesBalance}
      onBack={() => navigate(RouterName.AdminFinance)}
    />
  );
};
