import { FC, PropsWithChildren } from 'react';
import { useMediaQuery } from 'react-responsive';

import { MeQuery, RoleEnum, useMeQuery } from '@/apolloGenerated';
import { LogoIcon } from '@/shared';
import { HomeRouteName, RouterName } from '@app/router';
import {
  BagIcon,
  BookmarkIcon,
  CodeIcon,
  DocumentIcon,
  EditIcon,
  Header as HeaderUI,
  NavItem,
  useRouterPath,
  UserSquareIcon,
  WalletIcon,
} from '@letsdance/ui-kit';
import { useFeatureFlags } from '@shared/libs/hooks/useFeatureFlags';

import { ProfileControl } from '../ProfileControl/ProfileControl';

export interface HeaderProps {
  user?: MeQuery['me'];
  dense?: boolean;
  onNavClick?(): void;
}

export const Header: FC<PropsWithChildren<HeaderProps>> = ({
  dense,
  onNavClick,
}) => {
  const { checkActivePath } = useRouterPath();
  const { data } = useMeQuery();
  const { referral } = useFeatureFlags();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <HeaderUI
      dense={dense}
      homeLink={HomeRouteName[data?.me.role || RoleEnum.Organizer]}
      logoSlot={<LogoIcon color="var(--neutrals-1)" />}
      navigationSlot={
        <>
          {data?.me.role === RoleEnum.Organizer && (
            <>
              <NavItem
                to={RouterName.AdminProfile}
                icon={EditIcon}
                active={checkActivePath(RouterName.AdminProfile)}
                fullWidth
                hideLabel={dense}
                onClick={onNavClick}>
                Профиль
              </NavItem>
              <NavItem
                to={RouterName.AdminEvents}
                icon={BookmarkIcon}
                active={checkActivePath(RouterName.AdminEvents)}
                fullWidth
                hideLabel={dense}
                onClick={onNavClick}>
                Вечеринки
              </NavItem>
              <NavItem
                to={RouterName.AdminBaseTariffs}
                icon={DocumentIcon}
                active={checkActivePath(RouterName.AdminBaseTariffs)}
                fullWidth
                hideLabel={dense}
                onClick={onNavClick}>
                Тарифы
              </NavItem>
              <NavItem
                to={RouterName.AdminContact}
                icon={UserSquareIcon}
                active={checkActivePath(RouterName.AdminContact)}
                fullWidth
                hideLabel={dense}
                onClick={onNavClick}>
                Контакты
              </NavItem>
              <NavItem
                to={RouterName.AdminPurchases}
                icon={BagIcon}
                active={checkActivePath(RouterName.AdminPurchases)}
                fullWidth
                hideLabel={dense}
                onClick={onNavClick}>
                Билеты
              </NavItem>
              <NavItem
                to={RouterName.AdminFinance}
                icon={WalletIcon}
                active={checkActivePath(RouterName.AdminFinance)}
                fullWidth
                hideLabel={dense}
                onClick={onNavClick}>
                Финансы
              </NavItem>
              {referral && (
                <NavItem
                  to={RouterName.AdminReferral}
                  icon={CodeIcon}
                  active={checkActivePath(RouterName.AdminReferral)}
                  fullWidth
                  hideLabel={dense}
                  onClick={onNavClick}>
                  Рефер. система
                </NavItem>
              )}
            </>
          )}
          {data?.me.role === RoleEnum.Admin && (
            <NavItem
              to={RouterName.AdminOrganizers}
              icon={UserSquareIcon}
              active={checkActivePath(RouterName.AdminOrganizers)}
              fullWidth
              hideLabel={dense}
              onClick={onNavClick}>
              Организ.
            </NavItem>
          )}
        </>
      }
      bottomSlot={isMobile ? <></> : <ProfileControl />}
    />
  );
};
