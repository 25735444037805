import React, { FC, useState } from 'react';
import { useNavigate } from 'react-justanother-router';

import {
  BaseTariffInput,
  StatusEnum,
  useAllAllowedUsersForBaseTariffQuery,
  useBaseTariffQuery,
  UserType,
} from '@/apolloGenerated';
import {
  ActionButton,
  ActionButtons,
  ApolloErrorService,
  Container,
} from '@/shared';
import { RouterName } from '@app/router';
import {
  BaseTariffForm,
  BaseTariffInputSteps,
  useAccessTariffUsers,
  useUpdateBaseTariff,
} from '@entities/Tariff';
import { ContactTariffAccessSelect } from '@features/Contact';
import { Divider, LoaderOverlay, Spacer, toaster } from '@letsdance/ui-kit';
import { Head } from '@widgets/Layout';

export interface BaseTariffEditViewProps {
  params: {
    uuid: string;
  };
  query: {
    readonly?: boolean;
  };
}

export const BaseTariffEditView: FC<BaseTariffEditViewProps> = ({
  params: { uuid },
  query,
}) => {
  const { navigate } = useNavigate();
  const [updateEvent, { error, loading: saveLoading }] = useUpdateBaseTariff();
  const [tariffData, setTariffData] = useState<BaseTariffInput>();
  const [usersAccessUuids, setUserAccessUuids] = useState<string[]>([]);

  const { data, loading, refetch } = useBaseTariffQuery({
    variables: {
      uuid,
    },
  });
  const [updateUserAccessTariff, { loading: saveAccessLoading }] =
    useAccessTariffUsers();
  const { data: usersData } = useAllAllowedUsersForBaseTariffQuery({
    variables: { uuid },
  });
  const baseTariff = data?.baseTariff;
  const isArchive =
    baseTariff?.status === StatusEnum.Archive ||
    baseTariff?.status === StatusEnum.Draft;
  const isReadonly = !!query.readonly || isArchive;

  const onClose = () => {
    navigate(RouterName.AdminBaseTariffs);
  };
  const onSave = async () => {
    if (!tariffData) {
      return toaster.error({ title: 'Отсутствуют данные для обновления' });
    }
    await updateEvent({
      input: tariffData,
      uuid,
    });
    await updateUserAccessTariff({ userUuids: usersAccessUuids, uuid });
    refetch();
  };

  const actions: ActionButton[] = isReadonly
    ? [
        {
          handler: onClose,
          label: 'Отменить',
        },
        {
          handler: () => navigate(RouterName.AdminEventEdit, { uuid }),
          label: 'Редактировать',
        },
      ]
    : [
        {
          handler: onClose,
          label: 'Отменить',
        },
        {
          handler: onSave,
          label: 'Сохранить изменения',
        },
      ];

  return (
    <>
      <LoaderOverlay show={loading || saveLoading || saveAccessLoading} />
      <Container slim>
        <Head title={baseTariff?.name || ''} onBack={onClose} />
        <BaseTariffInputSteps data={baseTariff} />
      </Container>
      {isArchive ? (
        <Divider />
      ) : (
        <>
          <Spacer size={8} />
          <ActionButtons actions={actions} />
        </>
      )}
      <Spacer size={20} />
      <Container slim>
        {data && (
          <BaseTariffForm
            initValues={data.baseTariff}
            onChange={setTariffData}
            errors={
              ApolloErrorService.getFirstError(error?.graphQLErrors)?.extensions
                ?.validationErrors
            }
            readonly={isReadonly}
          />
        )}
        {usersData && (
          <ContactTariffAccessSelect
            initValue={
              (usersData.allAllowedUsersForBaseTariff || []) as UserType[]
            }
            onChange={setUserAccessUuids}
          />
        )}
      </Container>
    </>
  );
};
