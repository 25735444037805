import { FC, useMemo, useState } from 'react';

import {
  Maybe,
  OrderSortKeys,
  OrdersReferralOrganizerListQuery,
  OrderType,
  useOrdersReferralOrganizerListQuery,
} from '@/apolloGenerated';
import {
  calculateAmounts,
  Container,
  formatAmount,
  formatEmpty,
  formatSort,
  renderCellDate,
  renderCellItemValue,
  useOrganizerId,
} from '@/shared';
import { router, RouterName } from '@app/router';
import { renderCellEventName } from '@entities/Event';
import { OrderEventInfoCell } from '@entities/Purchases';
import {
  LoaderOverlay,
  SortType,
  TableController,
  Typography,
} from '@letsdance/ui-kit';

const headers = [
  {
    hide: true,
    key: true,
    value: 'key',
  },
  { sortable: true, title: 'Дата', value: 'createdAt', width: 160 },
  {
    sortable: true,
    title: 'Операция',
    value: 'operation',
    width: 140,
  },
  {
    title: 'Организатор',
    value: 'organizer',
  },
  {
    title: 'Мероприятие',
    value: 'event',
  },
  { title: 'Контрагент', value: 'user' },
  { title: 'Оплатил, ₽', value: 'price' },
  { title: 'Комиссия платформы, ₽', value: 'commission' },
  { title: 'Прибыль, ₽', value: 'profit' },
];
const pageSize = 15;

export const ReferralReceiptsTable: FC = () => {
  const organizerId = useOrganizerId()!;
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState<Maybe<SortType>>('desc');
  const [sortBy, setSortBy] = useState<OrderSortKeys>(OrderSortKeys.CreatedAt);

  const {
    data: curData,
    error,
    loading,
    previousData,
    refetch,
  } = useOrdersReferralOrganizerListQuery({
    skip: !organizerId,
    variables: {
      filter: {
        order: { sort: formatSort(sort), sortBy },
        pagination: { page, pageSize },
      },
      organizerId,
    },
  });

  const viewContact = (uuid: string) => {
    window.open(router.urlFor(RouterName.AdminContactEdit, { uuid }), '_blank');
  };

  const data = curData || previousData;
  const rowTemplate = (
    order: OrdersReferralOrganizerListQuery['ordersReferralOrganizerList']['rows'][0],
  ) => {
    const {
      createdAt,
      fixedHoldPercentage,
      id,
      organizer,
      payment,
      product,
      referralOrganizer,
      user,
    } = order;
    const { commission, referral, turnover } = calculateAmounts({
      holdPercentage: fixedHoldPercentage,
      price: payment!.price,
      referralPercentage: referralOrganizer?.percentage,
    });

    return {
      commission: formatEmpty(
        commission,
        `${formatAmount(commission)} (${fixedHoldPercentage}%)`,
      ),
      createdAt: renderCellDate({ time: true, timestamp: createdAt }),
      event: renderCellEventName({
        name: product.name,
        query: product.query,
        startedDate: product.startedDate,
      }),
      info: <OrderEventInfoCell order={order as OrderType} showPair />,
      key: id,
      operation: renderCellItemValue({
        label: `ID: ${id}`,
      }),
      organizer: organizer.name,
      price: formatAmount(turnover),
      profit: `${formatAmount(referral)} (${referralOrganizer?.percentage}%)`,
      user: renderCellItemValue({
        label: (
          <Typography
            variant="body-14"
            color="on-surface-primary-1"
            rel="noreferrer"
            className="cursor-pointer"
            onClick={() => viewContact(user.uuid)}>
            {user.last_name} {user.first_name}
          </Typography>
        ),
        value: user.email,
      }),
    };
  };
  const items = useMemo(
    () => (data?.ordersReferralOrganizerList.rows || []).map(rowTemplate),
    [data],
  );

  return (
    <Container className="relative">
      <LoaderOverlay show={loading} />
      <TableController
        hasError={!!error}
        data={items}
        headers={headers}
        total={data?.ordersReferralOrganizerList.count}
        onChangePage={setPage}
        pageSize={pageSize}
        initSort={sort}
        initSortBy={sortBy!}
        onSort={(sort, sortBy) => {
          setSort(sort);
          setSortBy(sortBy as OrderSortKeys);
        }}
        notResetPage
        onRefetch={error && refetch}
      />
    </Container>
  );
};
