import { OrganizerInput } from '@/apolloGenerated';

export const getOrganizerInput = ({
  description,
  image,
  name,
  orderInstructions: { refund, transferToProduct, transferToUser },
  query,
  socials: {
    facebook,
    image: imageSocial,
    instagram,
    telegram: tgSocial,
    text,
    vk,
    youtube,
  },
  supplierInfo: { inn, name: supplierName, phone },
  telegram,
}: OrganizerInput): OrganizerInput => ({
  description,
  image,
  name,
  orderInstructions: { refund, transferToProduct, transferToUser },
  query,
  socials: {
    facebook,
    image: imageSocial,
    instagram,
    telegram: tgSocial,
    text,
    vk,
    youtube,
  },
  supplierInfo: {
    inn,
    name: supplierName,
    phone,
  },
  telegram,
});

export const getOrganizerInitState = (): OrganizerInput => ({
  description: '',
  image: '',
  name: '',
  orderInstructions: {
    refund: '',
    transferToProduct: '',
    transferToUser: '',
  },
  socials: {
    facebook: null,
    image: '',
    instagram: null,
    text: '',
    vk: null,
    youtube: null,
  },
  supplierInfo: {
    inn: null,
    name: null,
    phone: null,
  },
  telegram: '',
});
