import React, { FC, useState } from 'react';
import { useNavigate } from 'react-justanother-router';

import {
  OrganizerInput,
  OrganizerType,
  useOrganizerQuery,
} from '@/apolloGenerated';
import {
  ActionButtons,
  ApolloErrorService,
  Container,
  FloatDriver,
} from '@/shared';
import { RouterName } from '@app/router';
import {
  OrganizerForm,
  OrganizerInputSteps,
  useUpdateOrganizer,
} from '@entities/Organizer';
import { UsersControlTable } from '@features/Contact';
import {
  Button,
  LoaderOverlay,
  PlusDenseIcon,
  Spacer,
  toaster,
  Typography,
} from '@letsdance/ui-kit';
import { ActionButton } from '@shared/ui/ActionButtons/ActionButtons';
import { Head } from '@widgets/Layout';
import { CreateOrganizerAdmin } from '@widgets/Organizer';

export interface OrganizerEditViewProps {
  params: {
    id: string;
  };
}
export const OrganizerEditView: FC<OrganizerEditViewProps> = ({ params }) => {
  const id = +params.id;
  const { navigate } = useNavigate();
  const [updateOrganizer, { error, loading: saveLoading }] =
    useUpdateOrganizer();
  const [organizerData, setOrganizerData] = useState<OrganizerInput>();
  const [isOpenAdministratorCreate, setIsOpenAdministratorCreate] =
    useState<boolean>(false);

  const { data, loading, refetch } = useOrganizerQuery({
    variables: {
      id,
    },
  });
  const organizer = data?.organizer;

  const onClose = () => {
    navigate(RouterName.AdminOrganizers);
  };
  const onSave = async () => {
    if (!organizerData) {
      return toaster.error({ title: 'Отсутствуют данные для обновления' });
    }
    await updateOrganizer({
      id,
      input: organizerData!,
    });
    refetch();
  };

  const handleCloseFloatDivider = () => {
    setIsOpenAdministratorCreate(false);
  };

  const actions: ActionButton[] = [
    {
      handler: onClose,
      label: 'Отменить',
    },
    {
      handler: onSave,
      label: 'Сохранить изменения',
    },
  ];

  return (
    <>
      <LoaderOverlay show={loading || saveLoading} />
      <Container slim>
        <Head title="Профиль" onBack={onClose} />
        <OrganizerInputSteps data={organizer} />
      </Container>
      <Spacer size={8} />
      <ActionButtons actions={actions} />
      <Spacer size={20} />
      <Container slim>
        {data && (
          <OrganizerForm
            initValues={data.organizer as OrganizerType}
            onChange={setOrganizerData}
            errors={
              ApolloErrorService.getFirstError(error?.graphQLErrors)?.extensions
                ?.validationErrors
            }
          />
        )}
        <Head
          title={
            <Typography variant="head-24" color="on-surface-primary-1">
              Администраторы
            </Typography>
          }
          rightSlot={
            <Button
              onClick={() => setIsOpenAdministratorCreate(true)}
              prependIcon={PlusDenseIcon}
              size="small">
              Администратор
            </Button>
          }
        />
        <UsersControlTable organizerId={id} />
        <FloatDriver
          isOpen={isOpenAdministratorCreate}
          onClose={handleCloseFloatDivider}>
          <Container>
            <Head
              title="Создание администратора"
              onBack={handleCloseFloatDivider}
            />
          </Container>
          <CreateOrganizerAdmin
            onClose={handleCloseFloatDivider}
            organizerId={id}
          />
        </FloatDriver>
      </Container>
    </>
  );
};
