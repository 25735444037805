import React, { FC, useState } from 'react';

import { StatusEnum, useEventsCountQuery } from '@/apolloGenerated';
import {
  Container,
  FloatDriver,
  ScrollXWrapper,
  useOrganizerId,
} from '@/shared';
import { EventListFilter, EventsTable } from '@features/Event';
import {
  Divider,
  InfoDenseIcon,
  Maybe,
  Spacer,
  TabItem,
  Tabs,
  Tooltip,
  Typography,
} from '@letsdance/ui-kit';
import { GiveOrderUsers, InviteOrderUsers } from '@widgets/Purchases';

export const EventsLists: FC = () => {
  const idOrganizer = useOrganizerId();
  const { data: countsData } = useEventsCountQuery({
    variables: { idOrganizer },
  });
  const counts = countsData?.productsCount;
  const [tab, setTab] = useState<StatusEnum | 'all'>(StatusEnum.Active);
  const [search, setSearch] = useState<string>('');
  const [createOrderData, setCreateOrderData] = useState<Maybe<string>>(null);
  const [inviteData, setInviteData] = useState<Maybe<string>>(null);

  console.log(setSearch);

  return (
    <div>
      <Container>
        <ScrollXWrapper>
          <Tabs initValue={tab} onChange={setTab}>
            <TabItem
              value={StatusEnum.Active}
              label={`Активные (${counts?.activeCount || 0})`}
            />
            <TabItem
              style={{ alignItems: 'center', display: 'flex', gap: 4 }}
              value={StatusEnum.Draft}>
              Черновики ({counts?.draftCount || 0}){' '}
              <Tooltip
                style={{ display: 'flex' }}
                width={300}
                label={
                  <Typography variant="body-14">
                    Вечеринка, сохранённая в черновики, не видна пользователям.
                    Для старта продаж переведите его в статус{' '}
                    <Typography tag="span" variant="head-14">
                      Активный.
                    </Typography>
                  </Typography>
                }>
                <InfoDenseIcon />
              </Tooltip>
            </TabItem>
            <TabItem
              value={StatusEnum.Archive}
              label={`Архивные (${counts?.archiveCount || 0})`}
            />
            <TabItem value={'all'} label={`Все (${counts?.count || 0})`} />
          </Tabs>
        </ScrollXWrapper>
      </Container>
      <Divider />
      <Container>
        <Spacer size={12} />
        <EventListFilter />
        <Spacer size={4} />
        <ScrollXWrapper>
          <EventsTable
            status={tab === 'all' ? null : tab}
            searchValue={search}
            onInvite={(productUuid) => setInviteData(productUuid)}
            onCreateOrder={(productUuid) => setCreateOrderData(productUuid)}
          />
        </ScrollXWrapper>
      </Container>
      <FloatDriver
        isOpen={!!createOrderData}
        onClose={() => setCreateOrderData(null)}>
        <GiveOrderUsers
          productUuid={createOrderData}
          onCancel={() => setCreateOrderData(null)}
        />
      </FloatDriver>
      <FloatDriver isOpen={!!inviteData} onClose={() => setInviteData(null)}>
        <InviteOrderUsers
          productUuid={inviteData}
          onCancel={() => setInviteData(null)}
        />
      </FloatDriver>
    </div>
  );
};
