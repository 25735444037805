import { FC, useState } from 'react';
import { useNavigate } from 'react-justanother-router';

import { OrganizerInput, OrganizerType } from '@/apolloGenerated';
import { RouterName } from '@/app/router';
import {
  ActionButton,
  ActionButtons,
  ApolloErrorService,
  Container,
} from '@/shared';
import {
  getOrganizerInitState,
  OrganizerForm,
  OrganizerInputSteps,
  useCreateOrganizer,
} from '@entities/Organizer';
import { LoaderOverlay, Spacer, toaster } from '@letsdance/ui-kit';
import { Head } from '@widgets/Layout';

export const OrganizerCreateView: FC = () => {
  const { navigate } = useNavigate();
  const [createOrganizer, { client, error, loading }] = useCreateOrganizer();
  const [data, setData] = useState<OrganizerInput>();

  const onClose = () => {
    navigate(RouterName.AdminOrganizers);
  };

  const onCreate = async () => {
    if (!data) {
      return toaster.error({ title: 'Отсутствуют данные для обновления' });
    }
    createOrganizer({
      input: data,
    });
    client.cache.evict({ fieldName: 'organizersList' });
  };

  const actions: ActionButton[] = [
    {
      handler: onClose,
      label: 'Отменить',
    },
    {
      handler: () => onCreate(),
      label: 'Создать',
    },
  ];

  return (
    <>
      <LoaderOverlay show={loading} />
      <Container slim>
        <Head title="Новый организатор" onBack={onClose} />
        <OrganizerInputSteps />
      </Container>
      <Spacer size={8} />
      <ActionButtons actions={actions} />
      <Spacer size={20} />
      <Container slim>
        <OrganizerForm
          initValues={getOrganizerInitState() as OrganizerType}
          onChange={setData}
          errors={
            ApolloErrorService.getFirstError(error?.graphQLErrors)?.extensions
              ?.validationErrors
          }
        />
      </Container>
    </>
  );
};
