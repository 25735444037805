import { FC } from 'react';

import { Container } from '@/shared';
import { RouterName } from '@app/router';
import { Button, PlusDenseIcon } from '@letsdance/ui-kit';
import { Head } from '@widgets/Layout';
import { OrganizersLists } from '@widgets/Organizer';

export const OrganizersView: FC = () => (
  <div>
    <Container>
      <Head
        title="Организаторы"
        rightSlot={
          <Button
            to={RouterName.AdminOrganizerCreate}
            prependIcon={PlusDenseIcon}>
            Добавить организатора
          </Button>
        }
      />
      <OrganizersLists />
    </Container>
  </div>
);
