import { FC, useMemo, useState } from 'react';

import {
  Maybe,
  OrderType,
  PaymentsListQuery,
  PaymentSortKeys,
  usePaymentsListQuery,
} from '@/apolloGenerated';
import {
  calculateAmounts,
  Container,
  formatAmount,
  formatEmpty,
  formatSort,
  renderCellDate,
  renderCellItemValue,
  useOrganizerId,
} from '@/shared';
import { router, RouterName } from '@app/router';
import { OrderEventInfoCell } from '@entities/Purchases';
import {
  LoaderOverlay,
  SortType,
  TableController,
  Typography,
} from '@letsdance/ui-kit';
import { useFeatureFlags } from '@shared/libs/hooks/useFeatureFlags';

const pageSize = 15;

export const ReceiptsTable: FC = () => {
  const organizerId = useOrganizerId()!;
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState<Maybe<SortType>>('desc');
  const [sortBy, setSortBy] = useState<PaymentSortKeys>(
    PaymentSortKeys.CreatedAt,
  );
  const { commission: commissionFlag, referralCreate: referralCreateFlag } =
    useFeatureFlags();
  const hasTurnover = commissionFlag || referralCreateFlag;
  const headers = [
    {
      hide: true,
      key: true,
      value: 'key',
    },
    { sortable: true, title: 'Дата', value: 'createdAt', width: 160 },
    {
      sortable: true,
      title: 'Операция',
      value: 'operation',
    },
    { title: 'Сведения', value: 'info' },
    { title: 'Контрагент', value: 'user' },
    ...(hasTurnover ? [{ title: 'Оборот, ₽', value: 'turnover' }] : []),
    ...(commissionFlag ? [{ title: 'Комиссия, ₽', value: 'commission' }] : []),
    ...(referralCreateFlag
      ? [{ title: 'Реф. отч., ₽', value: 'referral' }]
      : []),
    { title: 'Прибыль, ₽ ', value: 'profit' },
  ];

  const {
    data: curData,
    error,
    loading,
    previousData,
    refetch,
  } = usePaymentsListQuery({
    skip: !organizerId,
    variables: {
      filter: {
        order: { sort: formatSort(sort), sortBy },
        pagination: { page, pageSize },
      },
      organizerId,
    },
  });

  const viewContact = (uuid: string) => {
    window.open(router.urlFor(RouterName.AdminContactEdit, { uuid }), '_blank');
  };

  const data = curData || previousData;
  const rowTemplate = ({
    createdAt,
    order,
    payment_uuid,
    price,
    user,
    uuid,
  }: PaymentsListQuery['paymentsList']['rows'][0]) => {
    const { commission, profit, referral, turnover } = calculateAmounts({
      holdPercentage: order.fixedHoldPercentage,
      price,
      referralPercentage: order.referralOrganizer?.percentage,
    });

    return {
      commission: formatEmpty(commission, `- ${formatAmount(commission)}`),
      createdAt: renderCellDate({ time: true, timestamp: createdAt }),
      info: <OrderEventInfoCell order={order as OrderType} showPair />,
      key: uuid,
      operation: renderCellItemValue({
        label: `ID: ${order.id}`,
        value: payment_uuid,
      }),
      profit: formatAmount(profit),
      referral: formatEmpty(referral, `- ${formatAmount(referral)}`),
      turnover: formatAmount(turnover),
      user: renderCellItemValue({
        label: (
          <Typography
            variant="body-14"
            color="on-surface-primary-1"
            rel="noreferrer"
            className="cursor-pointer"
            onClick={() => viewContact(user.uuid)}>
            {user.last_name} {user.first_name}
          </Typography>
        ),
        value: user.email,
      }),
    };
  };
  const items = useMemo(
    () => (data?.paymentsList.rows || []).map(rowTemplate),
    [data],
  );

  return (
    <Container className="relative">
      <LoaderOverlay show={loading} />
      <TableController
        hasError={!!error}
        data={items}
        headers={headers}
        total={data?.paymentsList.count}
        onChangePage={setPage}
        pageSize={pageSize}
        initSort={sort}
        initSortBy={sortBy!}
        onSort={(sort, sortBy) => {
          setSort(sort);
          setSortBy(sortBy as PaymentSortKeys);
        }}
        notResetPage
        onRefetch={error && refetch}
      />
    </Container>
  );
};
