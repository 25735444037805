import React, { FC, useState } from 'react';

import { StatusEnum, useBaseTariffsListQuery } from '@/apolloGenerated';
import { Container, ScrollXWrapper, useOrganizerId } from '@/shared';
import { BaseTariffsTable } from '@features/Tariff';
import { Divider, Spacer, TabItem, Tabs } from '@letsdance/ui-kit';

export const BaseTariffsLists: FC = () => {
  const idOrganizer = useOrganizerId()!;
  const getParams = (status?: StatusEnum[]) => ({
    variables: {
      filters: { pagination: { page: 1, pageSize: 1 } },
      idOrganizer,
      status: status?.length ? status : null,
    },
  });
  const { data: activeCountsData } = useBaseTariffsListQuery(
    getParams([StatusEnum.Active]),
  );
  const { data: archiveCountsData } = useBaseTariffsListQuery(
    getParams([StatusEnum.Archive]),
  );
  const { data: allCountsData } = useBaseTariffsListQuery(
    getParams([StatusEnum.Active, StatusEnum.Archive]),
  );
  const [tab, setTab] = useState<StatusEnum | 'all'>(StatusEnum.Active);

  return (
    <div>
      <Container>
        <ScrollXWrapper>
          <Tabs initValue={tab} onChange={setTab}>
            <TabItem
              value={StatusEnum.Active}
              label={`Активные (${activeCountsData?.baseTariffsList?.count || 0})`}
            />
            <TabItem
              value={StatusEnum.Archive}
              label={`Архивные (${archiveCountsData?.baseTariffsList.count || 0})`}
            />
            <TabItem
              value={'all'}
              label={`Все (${allCountsData?.baseTariffsList.count || 0})`}
            />
          </Tabs>
        </ScrollXWrapper>
      </Container>
      <Divider />
      <Container>
        <Spacer size={12} />
        <ScrollXWrapper>
          <BaseTariffsTable
            status={
              tab === 'all' ? [StatusEnum.Active, StatusEnum.Archive] : [tab]
            }
          />
        </ScrollXWrapper>
      </Container>
    </div>
  );
};
