import { Route } from 'react-justanother-router';

import { RoleEnum } from '@/apolloGenerated';
import { RouterName } from '@app/router';
import * as views from '@pages/Admin';
import { AuthLayout, UserLayout } from '@widgets/Layout';

export const adminRoutes: Route[] = [
  {
    component: views.AuthView,
    name: RouterName.Authorization,
    path: '/login',
    props: {
      layout: AuthLayout,
    },
  },
  {
    children: [
      {
        component: views.EventEditView,
        name: RouterName.AdminEventEdit,
        path: '/edit/:uuid',
        props: {
          accessRole: [RoleEnum.Organizer],
          layout: UserLayout,
        },
      },
      {
        component: views.EventCreateView,
        name: RouterName.AdminEventCreate,
        path: '/create',
        props: {
          accessRole: [RoleEnum.Organizer],
          layout: UserLayout,
        },
      },
    ],
    component: views.EventsView,
    name: RouterName.AdminEvents,
    path: '/events',
    props: {
      accessRole: [RoleEnum.Organizer],
      layout: UserLayout,
    },
  },
  {
    children: [
      {
        component: views.BaseTariffEditView,
        name: RouterName.AdminBaseTariffEdit,
        path: '/edit/:uuid',
        props: {
          accessRole: [RoleEnum.Organizer],
          layout: UserLayout,
        },
      },
      {
        component: views.BaseTariffCreateView,
        name: RouterName.AdminBaseTariffCreate,
        path: '/create',
        props: {
          accessRole: [RoleEnum.Organizer],
          layout: UserLayout,
        },
      },
    ],
    component: views.BaseTariffsView,
    name: RouterName.AdminBaseTariffs,
    path: '/tariffs',
    props: {
      accessRole: [RoleEnum.Organizer],
      layout: UserLayout,
    },
  },
  {
    children: [
      {
        component: views.ContactEditView,
        name: RouterName.AdminContactEdit,
        path: '/edit/:uuid',
        props: {
          accessRole: [RoleEnum.Organizer],
          layout: UserLayout,
        },
      },
    ],
    component: views.ContactView,
    name: RouterName.AdminContact,
    path: '/contacts',
    props: {
      accessRole: [RoleEnum.Organizer],
      layout: UserLayout,
    },
  },
  {
    component: views.PurchasesView,
    name: RouterName.AdminPurchases,
    path: '/purchases',
    props: {
      accessRole: [RoleEnum.Organizer],
      layout: UserLayout,
    },
  },
  {
    children: [
      {
        component: views.CreateWithdrawView,
        name: RouterName.AdminWithdrawCreate,
        path: '/withdraw',
        props: {
          accessRole: [RoleEnum.Organizer],
          layout: UserLayout,
        },
      },
    ],
    component: views.FinanceView,
    name: RouterName.AdminFinance,
    path: '/finances',
    props: {
      accessRole: [RoleEnum.Organizer],
      layout: UserLayout,
    },
  },
  {
    children: [
      {
        component: views.CreateReferralWithdrawView,
        name: RouterName.AdminReferralWithdrawCreate,
        path: '/withdraw',
        props: {
          accessRole: [RoleEnum.Organizer],
          layout: UserLayout,
        },
      },
    ],
    component: views.ReferralView,
    name: RouterName.AdminReferral,
    path: '/referral',
    props: {
      accessRole: [RoleEnum.Organizer],
      layout: UserLayout,
    },
  },
  {
    component: views.ProfileOrganizerView,
    name: RouterName.AdminProfile,
    path: '/profile',
    props: {
      accessRole: [RoleEnum.Organizer],
      layout: UserLayout,
    },
  },
  {
    children: [
      {
        component: views.OrganizerEditView,
        name: RouterName.AdminOrganizerEdit,
        path: '/edit/:id',
        props: {
          accessRole: [RoleEnum.Admin],
          layout: UserLayout,
        },
      },
      {
        component: views.OrganizerCreateView,
        name: RouterName.AdminOrganizerCreate,
        path: '/create',
        props: {
          accessRole: [RoleEnum.Admin],
          layout: UserLayout,
        },
      },
    ],
    component: views.OrganizersView,
    name: RouterName.AdminOrganizers,
    path: '/organizers',
    props: {
      accessRole: [RoleEnum.Admin],
      layout: UserLayout,
    },
  },
];
